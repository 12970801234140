import React, {useState} from "react"

import Layout from "../components/layout"
import PostCard from "../components/posts/PostCard"
import BlogFooter from "../components/posts/BlogFooter"
import Breadcrumbs from "../components/Breadcrumbs"
import Pagination from "../components/Pagination"
import Seo from "@components/Seo.js"
import TheCategories from "../components/TheCategories"
import categories from "../content/essayCategories.json"
import filterBtn from "../images/filter.svg"

import { StructuredDataHelper } from "@helpers/structuredDataHelper.js"

const Essay = ({pageContext}) => {
    const structuredData = new StructuredDataHelper(pageContext)
    const posts = pageContext.content
    const pages = pageContext.pages
    const categoriesLink = pageContext.categoriesLink
    const steps = [
        {
            name: 'Sample Essays',
            path: '/sample-essays'
        }
    ]
    const category = pageContext.category || ''
    const subcategory = pageContext.subcategory || ''

    const [showFilter, setShowFilter] = useState(false)

    const hideFilter = () => {
        setShowFilter(!showFilter)
    }
    const itemListElements = [
        {
            position: 1,
            name: 'CustomEssayMeister',
            item: 'https://www.customessaymeister.com/'
        },
        {
            position: 2,
            name: '✔️ Free Essays',
            item: 'https://www.customessaymeister.com/sample-essays'
        }
    ]
    
    const pageDataMain = {
        title: pageContext.counter === 1 ? 'Free Essays' : `Free Research Papers, Term Papers | Page ${pageContext.counter}`,
        description: `⭐ Best free college essays, term papers, research papers. Database of free study guides and homework help Page № ${pageContext.counter}`,
        keywords: `essays, term papers, book reports, cliff notes, study guides, homework help`,
        // robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        robots: 'noindex, follow',
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }
    
    const pageDataSubcategory = {
        title: pageContext.counter === 1 ? `Free Examples Essays: ${category} on ${subcategory}` : `Term Papers: ${category} on ${subcategory} | Page ${pageContext.counter}`,
        description: `⭐ Best free college paper examples, ${category.toLowerCase()} on ${subcategory.toLowerCase()} topic. Page № ${pageContext.counter}`,
        keywords: `${subcategory.toLowerCase()} essays, ${subcategory.toLowerCase()} term papers, ${subcategory.toLowerCase()} research papers`,
        // robots: pageContext.counter === 1 ? 'index, follow' : 'noindex, follow',
        robots: 'noindex, follow',
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }
    const pageData = subcategory ? pageDataSubcategory : pageDataMain
    return (
        <>
            <Seo {...pageData} />
            <Layout>
                <div className="essay-intro">
                    <div className="wrapper">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title">
                            {subcategory ? `Free Essays | ${subcategory}` : 'Free Example Essays'}
                            <span
                                className="filterBtn button button-primary"
                                role={'presentation'}
                                onClick={hideFilter}
                            >
                                <img src={filterBtn} alt="filter" width={30} height={30} />
                            </span>      
                        </h1>
                    </div>
                </div>
                <div className="essay-content wrapper">
                    <div className="essay">
                        {posts.length && posts.map(item => <PostCard  key={item.id} post={item} name="/essay" footer={<BlogFooter postInfo={item}/>}/>)}
                    </div>
                    <div className="essay-categories">
                        <TheCategories 
                            categories={categories}
                            currentCategories={category}
                            currentSubCategories={subcategory}
                            url="sample-essays/category"
                            title="Essay categories"
                            showFilter={showFilter}
                            hideFilter={hideFilter}
                        />
                    </div>
                    <Pagination pageName={categoriesLink ? `sample-essays/${categoriesLink}` : 'sample-essays'} pages={pages} count={pageContext.counter}/>
                </div>
            </Layout>
        </>
    )
}

export default Essay
